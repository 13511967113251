import { colors, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import PropTypes from 'prop-types';

import { Icon } from '@/src/components/atoms';
import { CardBase, DescriptionListIcon } from '@/src/components/organisms';

const useStyles = makeStyles(theme => ({
  iconCover: {
    width: 60,
    height: 60,
    background:
      'url(https://assets.maccarianagency.com/the-front/illustrations/bgicon.svg) no-repeat center center',
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 35,
    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 80,
      fontSize: 50,
    },
  },
}));

const items = [
  {
    id: 1,
    title: 'Escolas e Redes de Ensino',
    icon: 'fas fa-graduation-cap',
    color: colors.orange[500],
  },
  { id: 2, title: 'Sistemas de Ensino', icon: 'fas fa-book-reader', color: colors.orange[500] },
  { id: 3, title: 'Lojas de Uniformes', icon: 'fas fa-tshirt', color: colors.orange[500] },
  {
    id: 4,
    title: 'Cursos Extracurriculares',
    icon: 'fas fa-chalkboard-teacher',
    color: colors.orange[500],
  },
  { id: 5, title: 'Livrarias', icon: 'fas fa-book-open', color: colors.orange[500] },
  { id: 6, title: 'Editoras', icon: 'fas fa-print', color: colors.orange[500] },
  { id: 7, title: 'e muito mais...', icon: 'fas fa-plus-square', color: colors.orange[500] },
];

export default function Segments({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }} mb={4}>
        Atendemos diversos segmentos:
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {items.map(item => (
          <Grid item key={item.id} xs={12} sm={4} md={3}>
            <CardBase withShadow liftUp>
              <DescriptionListIcon
                icon={
                  <div className={classes.iconCover}>
                    <Icon fontIconClass={item.icon} fontIconColor={item.color} size="medium" />
                  </div>
                }
                title={item.title}
                titleVariant="h6"
                alignContent="center"
                sx={{ height: '100%' }}
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Segments.propTypes = {
  className: PropTypes.string,
};
